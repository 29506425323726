import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PopUpComponent from "../../components/Nav/PopUpComponent";
import EffectComponent from "../../components/Photo/EffectComponent";

const GreenScreenComponent = ({
                                  isPNG,
                                  product_type,
                                  primary_color_4,
                                  background_color_2,
                                  messages,
                                  effectData,
                                  effectFileNameFor,
                                  effectFileNameBehind,
                                  user_id,
                                  photo_id,
                                  closepopup_effect,
                                  t
                              }) => {
    const [showBackgroundPopup, setShowBackgroundPopup] = useState(false);
    const [showOverlayPopup, setShowOverlayPopup] = useState(false);
    const isNotDivers = product_type !== 'divers';
    const buttonStyle = {
        color: primary_color_4,
        backgroundColor: background_color_2,
    };

    const getEffectsCount = (type) =>
        effectData ? effectData.filter(effect => effect.voor_achter === type).length : 0;

    const achterCount = effectData?.some(effect => effect.voor_achter === 'achter');
    const voorCount = effectData?.some(effect => effect.voor_achter === 'voor');

    const showBackgroundButton = isPNG && isNotDivers && achterCount;
    const showOverlayButton = isNotDivers && voorCount;
    const showClearBackgroundButton = isNotDivers && (achterCount || voorCount) && (effectFileNameFor || effectFileNameBehind);


    const clearAllEffects = () => {
        closepopup_effect('', '', 'voor');
        closepopup_effect('', '', 'achter');
    };

    useEffect(() => {
        if (!messages.message) {
            setShowBackgroundPopup(false);
            setShowOverlayPopup(false);
        }
    }, [messages.message, closepopup_effect]);

    const renderPopupButton = (type, count, text, showPopup, setShowPopupFunction) => (
        <Grid item lg={6} className="card-footer-item Number">
            <Button
                style={buttonStyle}
                className="btn btn-light form-control btn-layout"
                onClick={() => setShowPopupFunction(prev => !prev)}
            >
                {t(text)}
            </Button>
            {showPopup && (
                <PopUpComponent
                    text={<Alert severity="info">{t(text)}</Alert>}
                    content={
                        <EffectComponent
                            user_id={user_id}
                            effectData={effectData}
                            photo_id={photo_id}
                            product_type={product_type}
                            type={type}
                            closepopup_effect={closepopup_effect}
                        />
                    }
                    btn2={t("shoppingcart:Continue Shopping")}
                    closePopup={() => setShowPopupFunction(false)}
                />
            )}
        </Grid>
    );

    return (
        <Grid container>
            {showBackgroundButton && renderPopupButton('achter', achterCount, "shoppingcart:Background", showBackgroundPopup, setShowBackgroundPopup)}
            {showOverlayButton && renderPopupButton('voor', voorCount, "shoppingcart:Overlay", showOverlayPopup, setShowOverlayPopup)}
            {showClearBackgroundButton && (
                <Grid item lg={6} className="card-footer-item Number">
                    <Button
                        style={buttonStyle}
                        className="btn btn-light form-control btn-layout"
                        onClick={clearAllEffects}>
                        {t("shoppingcart:Clear Background")}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default GreenScreenComponent;
